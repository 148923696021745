<template>
  <div v-if="show" class="loading-mask">
    <div class="sk-cube-grid">
      <div class="sk-cube sk-cube1"></div>
      <div class="sk-cube sk-cube2"></div>
      <div class="sk-cube sk-cube3"></div>
      <div class="sk-cube sk-cube4"></div>
      <div class="sk-cube sk-cube5"></div>
      <div class="sk-cube sk-cube6"></div>
      <div class="sk-cube sk-cube7"></div>
      <div class="sk-cube sk-cube8"></div>
      <div class="sk-cube sk-cube9"></div>
    </div>
    <p>加载中...</p>
    <p>{{ percentage }}%</p>
  </div>
</template>

<script>
export default {
  name: 'PreloadImage',
  data() {
    return {
      loadedCount: 0,
      show: true
    }
  },
  computed: {
    percentage() {
      return Math.round((this.loadedCount / this.imgArr.length) * 100)
    }
  },
  created() {
    console.log('-----------------')
    this.unorderedPreload()
  },
  methods: {
    // 无序加载图片
    unorderedPreload() {
      const imagesContext = require.context(
        '@/assets/',
        true,
        /\.(png|jpeg|jpg|gif)$/
      )
      this.imgArr = imagesContext.keys().map(imagesContext)
      console.log('imgArr', this.imgArr)

      this.imgArr.forEach(imgUrl => {
        console.log('imgUrl', imgUrl)
        const imgObj = new Image()
        imgObj.addEventListener('load', this.imageLoaded)
        imgObj.addEventListener('error', this.imageLoaded)
        imgObj.src = imgUrl
      })
    },
    // 加载完成后执行的回调
    imageLoaded() {
      this.loadedCount += 1
      if (this.loadedCount >= this.imgArr.length) {
        this.show = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
// 加载动画
.loading-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 99;
  color: #333;
}

.sk-cube-grid {
  margin: -20px 0 20px;
  width: 40px;
  height: 40px;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: #14066e;
  float: left;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}

.sk-cube-grid .sk-cube1 {
  animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube2 {
  animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube3 {
  animation-delay: 0.4s;
}

.sk-cube-grid .sk-cube4 {
  animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube5 {
  animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube6 {
  animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube7 {
  animation-delay: 0s;
}

.sk-cube-grid .sk-cube8 {
  animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube9 {
  animation-delay: 0.2s;
}

@keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    transform: scale3D(1, 1, 1);
  }
  35% {
    transform: scale3D(0, 0, 1);
  }
}
</style>
