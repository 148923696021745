<template>
  <div class="page">
    <preload-image />
    <div class="title">
      <small>2024年江苏省医疗卫生行业综合监管</small>
      <p>职业卫生网络知识竞赛</p>
    </div>
    <!-- <img class="sub-title" :src="require('@/assets/sub_title.png')" alt="" /> -->
    <!-- <div class="sub-title-text">坚持预防为主 守护职业健康</div> -->
    <img class="title-line" alt="" />
    <div></div>
    <!-- <img class="logo" :src="require('@/assets/logo.png')" alt="" /> -->
    <div style="height: 46vh"></div>
    <img
      class="button"
      :src="require('@/assets/button1.png')"
      alt=""
      @click="onStart"
    />
    <div class="content">
      <p>
        <span>活动时间：</span>
      </p>
      <p>2024年4月25日—5月1日</p>
      <p>
        <span>主办单位：</span>
      </p>
      <p>江苏省卫生健康委员会</p>
      <p>
        <span>协办单位：</span>
      </p>
      <p>江苏省卫生监督所</p>
      <p>江苏省医疗管理服务指导中心</p>
    </div>
    <router-view />
  </div>
</template>
<script>
import { Toast } from 'vant'
import moment from 'moment'
import PreloadImage from '@/components/PreloadImage'
// import storage from 'store'

export default {
  name: 'Index',
  components: {
    PreloadImage
  },
  methods: {
    onStart() {
      // Toast.success(`恭喜您获得\n${0.3}红包`)
      if (
        moment().isBefore(moment('2024-04-25 00:00:00', 'YYYY-MM-DD HH:mm:ss'))
      ) {
        Toast.fail('活动暂未开始')
      } else if (
        moment().isAfter(moment('2024-05-01 23:59:59', 'YYYY-MM-DD HH:mm:ss'))
      ) {
        Toast.fail('活动已经结束')
      } else {
        this.$router.push('/home')
      }
      //
      // const router = this.$router.resolve('/result')
      // console.log(this.$route)
      // console.log(this.$router)
      // // console.log(router)
      // // console.log(router.href)
      // console.log(window.location)
      // console.log(
      //   window.location.protocol + '//' + window.location.host + '/#/result'
      // )
      // window.open(
      //   window.location.protocol + '//' + window.location.host + '/#/result'
      // )

      // this.$router.push('/home')
    }
  }
}
</script>
<style lang="less" scoped>
.page {
  text-align: center;
  // color: #ffffff;
  color: #0e53a0;

  padding: 0 0;
}
.title {
  font-size: 1.45rem;
  font-family: Source Han Sans CN;
  font-weight: 700;
  letter-spacing: 1px;
  margin: 0 auto;
  padding-top: 4vh;
  text-align: center;
  text-shadow: #ffffff 2px 0 0, #ffffff 0 2px 0, #ffffff -2px 0 0,
    #ffffff 0 -2px 0;
  p {
    margin: 0;
    margin-top: 0.2rem;
  }
}
.sub-title {
  margin-top: 3vh;
  width: 100%;
}

.sub-title-text {
  margin-top: 3vh;
  font-weight: 700;
  width: 100%;
  font-size: 1.5rem;
  font-family: Source Han Sans CN;

  background-image: linear-gradient(
    to bottom,
    #ffffff,
    #b9d1ef
  ); // 背景线性渐变
  color: #ffffff; // 兜底颜色，防止文字裁剪不生效
  background-clip: text;
  -webkit-background-clip: text; // 背景被裁减为文字的前景色
  -webkit-text-fill-color: transparent; // 文字填充为透明，优先级比color高。
}

.title-line {
  width: 100%;
  visibility: hidden;
}
.logo {
  margin-top: 3vh;
  width: 12vh;
  visibility: hidden;
}
.content {
  // margin-top: 6vh;
  font-size: 1rem;
  color: #ffffff;
  p {
    margin: 0;
    margin-bottom: 10px;
    line-height: 2vh;
    span {
      font-weight: 700;
    }
  }

  .sub-text {
    font-size: 0.8rem;
    color: #c1d1eb;
    margin: 0;
    margin-bottom: 4px;
    span {
      font-weight: 500;
    }
  }
}
.button {
  margin-top: 2vh;
  width: 56vw;
  cursor: pointer;
}
</style>
